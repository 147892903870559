import React from "react"
import styled from '@emotion/styled'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundWrapper>
      <h2>PAGE NOT FOUND.</h2>
      <p>You just hit a route that doesn&#39;t exist or has been removed. <Link to="/">Back to home?</Link></p>
    </NotFoundWrapper>
  </Layout>
)

export default NotFoundPage

// Component Styles
const NotFoundWrapper = styled.div`
margin-top: var(--md);
text-align: center;
  h2 {
    color: var(--white);
    margin-bottom: var(--xxs);
  }
  p {
    color: var(--gray);
  }
  a {
    color: var(--sky);
    :hover {
      color: var(--green);
    }
  }
`